@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.xrimatodotisiCl {
    position: absolute;
    left: 10px;
    display: block;
}

.backgroundContainer {
    background-size: cover;
}

.header {
    background-color: white;
    width: 100%;
    height: auto;
    padding: 20px;
    border-bottom: solid 3px #2D9BEE;
    align-self: start;
}


.logo {
    position: relative;
    display: inline-block;
    width: 467px;
    height: 61px;
    background-size: cover;
    vertical-align: bottom;
    cursor: pointer;
    margin-right: 40px;
}

.logo_dot {
    position: relative;
    display: inline-block;
    width: 87px;
    height: 20px;
    background-size: cover;
    vertical-align: middle;
    cursor: pointer;
    float: right;
}

.middle {
    width: 100%;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color:rgba(255,255,255,0.7);
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;  
    box-shadow: 1px 1px 1px 1px #000;
}


.text_dot {
    position: relative;
    display: inline-block;
    width: auto;
    height: 20px;
    vertical-align: middle;
    float: right;
    color: grey;
    margin-right: 5px;
}

.text_footer {
    position: relative;
    display: inline-block;
    width: auto;
    height: 20px;
    vertical-align: middle;
    float: left;
    color: grey;
}



.logotext {
    position: relative;
    display: inline-block;
    width: auto;
    height: 100%;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.4;
    border-left: solid 5px #37545C;
    padding-left: 30px;
    text-shadow: 1px 1px grey;
    color: #37545C;
}

.footer {
    background-color: white;
    float: both;
    width: 100%;
    height: auto;
    padding: 10px;
    position: "fixed";
    left: 0;
    bottom: 0;
    border-top: solid 1px black;
    align-self: flex-end;
}

.flexContainer {
    margin: 0px;
    padding: 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    flex-wrap: wrap;    
}

.backgroundItem1 {
    position: relative;
    background-size: cover;
}

.backgroundItem1:hover {
    opacity: 0.75;
    box-shadow: 3px 3px 4px 5px #000;
}

.backgroundItem2:hover {
    opacity: 0.75;
    box-shadow: 3px 3px 4px 5px #000;
}

.backgroundItem3:hover {
    opacity: 0.75;
    box-shadow: 3px 3px 4px 5px #000;
}

.backgroundItem2 {
    position: relative;
    background-size: cover;
}

.backgroundItem3 {
    position: relative;
    background-size: cover;
}

.holder {
    position: absolute;
    bottom: 80px;
    left: 10%;
    margin: auto;
    width: 80%;
    white-space: normal;
}

.textbox {
    display: block;
    color: white;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.4;
    word-wrap: break-word;
    vertical-align: middle;
    padding-bottom: 10px;
    text-shadow: 2px 3px black;
    border-bottom: solid 15px #FA9B99;
}

.show-grid {
    border: 1px dotted black;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    overflow-x: hidden;
}

.header_int {
    height: 60px;
    background-color: #fff;
    margin: 0px;
    width: 100%;
    padding-bottom: 5px;
    z-index: +1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid black;
}

.main_banner {
    max-height: 576px !important;
    height: 45vh;
    overflow: hidden;
}

.main_banner img {
    transform: scale(1);
    transition: 1s ease-in-out;
  }

.main_banner:hover img {
    transform: scale(1.2);
    
  }


.text_after_banner {
    width: 80%;
    padding-bottom: 30px;
    padding-top: 30px;
}
.container_intro {
    width: calc(100% - 250px);
    position: absolute;
    right: 10px;
    top: 0px;
    height: 56px;
}

.menu_cl {
    z-index: +1000;
    background-color: white;
    float: right;
    max-height: 56px;
    width: 100%;
}

.nav.navbar-nav.navbar-right li a {
    color: #595959;
}
.nav.navbar-nav.navbar-right li a:hover {
    color: black;
    border-bottom: 3px solid #B0B91B;
}

.logo_holder {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    width: 230px;
    height: 56px;
}

.navbar-inverse .navbar-toggle, .ms2 .navbar-inverse .navbar-toggle {
    background-color: white !important;
    border-color: white !important;
    border-bottom: 3px solid white !important;
    margin-bottom: 15px;
}

.navbar-inverse .navbar-toggle:hover, .ms2 .navbar-inverse .navbar-toggle {
    background-color: white !important;
    border-bottom: 3px solid #B0B91B !important;
}


.navbar .navbar-toggle .icon-bar {
    background-color:#595959 !important;
  }
  

.navbar .navbar-toggle:hover .icon-bar {
    background-color: black !important;
  }

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 0px solid transparent !important;
}


.banner_text {
    position: absolute;
    top: 15%;
    left: 2%;
    width: 20%;
    color: white;
    border-bottom: 3px solid #B0B91B;
    z-index: +200;
    padding-bottom: 10px;
    font-size: 23px
}

.welcome_text {
    font-size: 23px;
}

.footerCl {
    background-color: #eaeaea;
    border-top: 1px solid gray;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 31px;
}

.navbar-collapse {
 float: right;
 background-color: white;
 position: absolute;
 right: 0px;
}

.navbar-header {
    width: 100%;
}

.menu-tab {
    display: block;
    width: 246px;
    min-width: 246px;
    margin: 0 5px 10px;
    padding: 0.375rem 0.75rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 3px solid white;
    cursor: pointer;
}

.menu-tab:hover {
    border-bottom: 3px solid #B0B91B;
    opacity: 0.5;
}

.menu-tab-active {
    display: block;
    width: 246px;
    min-width: 246px;
    margin: 0 5px 10px;
    padding: 0.375rem 0.75rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 3px solid white;
    cursor: pointer;
    border-bottom: 3px solid #B0B91B;
}

.border-tabs {
    border-bottom: 1px solid black;
}

.ef_title {
    margin-bottom: 20px;
    margin-top: 10px;
    font-weight: 300;
    font-size: 23px;
}

.btn-default-new {
    color: black;
    background-color: #ffffff;
    border-color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 20px;
    float: left;
    width: 220px;
    height: 45px;
    cursor: pointer;
}

.btn-default-new:hover {
    box-shadow: inset 0 0 6px grey;
}

.btn-default-new:active {
    box-shadow: inset 0 0 6px grey;
}

.tabsCont::-webkit-scrollbar {
    height: 3px;
    cursor: pointer;
  }
  
  /* Track */
.tabsCont::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
    border-radius: 15px;
  }
  
  /* Handle */
.tabsCont::-webkit-scrollbar-thumb {
    background: #888;
    cursor: pointer;
    border-radius: 15px;
  }
  
  /* Handle on hover */
.tabsCont::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
    border-radius: 15px;
  }


.inStyleR {
    padding-left: 30px;
}


.tabsCont {
    width: 75%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: no-wrap;
    overflow-x: scroll;
}

.imageClass {
    max-height: 290px !important;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 30px;
}

.flex_left_text_parent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 480px;
}

.flex_right_text_parent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 480px;
}

.flex_right_text {
    width: 50%;
    height: auto;
    min-width: 360px;
    order: 0;
}

.flex_left_text {
    width: 50%;
    height: auto;
    min-width: 360px;
    order: 5;
}

.flex_all_cont {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 30px;
}

.titleh2 {
    padding-top: 30px;
    font-size: 23px !important;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}


  @media (max-width: 1023px) {

    .flex_right_text {
        width: 100%;
        order: 5;
        min-width: 0px;
    }
    
    .flex_left_text {
        width: 100%;
        order: 0;
        min-width: 0px;
        margin-bottom: 40px;
    }

    .flex_all_cont {
        width: 90%;
        display: flex;
        align-items:flex-start;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 0px;
        margin-top: 20px;
    }

    .flex_left_text_parent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 0px;
    }
    
    .flex_right_text_parent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 0px;
    }

    .text_after_banner {
        width: 90%
    }

    .inStyleR {
        padding-left: 0px;
    }

    .text_after_banner {
        padding-top: 10px;
    }

    .titleh2 {
        padding-top: 10px;
    }

    .container_intro {
        right: 0px;
    }

    .footerCl {
        margin-top: 20px;
    }

    .banner_text {
        width: 60%;
    }
    

}

